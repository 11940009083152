import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';

// import p1 from 'assests/Photos/Clubs/LitreryClub/2023/ShowNTell/1.jpg';
// import p2 from 'assests/Photos/Clubs/LitreryClub/2023/ShowNTell/2.jpg';
// import p3 from 'assests/Photos/Clubs/LitreryClub/2023/ShowNTell/3.jpg';
// import p4 from 'assests/Photos/Clubs/LitreryClub/2023/ShowNTell/4.jpg';
// import p5 from 'assests/Photos/Clubs/LitreryClub/2023/ShowNTell/5.jpg';
// import p6 from 'assests/Photos/Clubs/LitreryClub/2023/ShowNTell/6.jpg';
// import p7 from 'assests/Photos/Clubs/LitreryClub/2023/ShowNTell/7.jpg';
// import p8 from 'assests/Photos/Clubs/LitreryClub/2023/ShowNTell/8.jpg';
// import p9 from 'assests/Photos/Clubs/LitreryClub/2023/ShowNTell/9.jpg';
// import p10 from 'assests/Photos/Clubs/LitreryClub/2023/ShowNTell/10.jpg';
// import p11 from 'assests/Photos/Clubs/LitreryClub/2023/ShowNTell/11.jpg';
// import p12 from 'assests/Photos/Clubs/LitreryClub/2023/ShowNTell/12.jpg';
// import p13 from 'assests/Photos/Clubs/LitreryClub/2023/ShowNTell/13.jpg';

import { Typography } from '@mui/material';
import Container from 'components/Container';
import { Sidebar2023 } from 'views/Literaryclub2023/components';
import { LiterarySidebar } from 'views/Literaryclub2023/components';

const Literaryclub2023 = () => {
    const theme = useTheme();
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);

    const openLightbox = (index) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    };

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };

    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });

    const [config, setConfig] = useState({ base_image_archive_url: '' });
    useEffect(() => {
      fetch('/config.json')  // For Gatsby, this will resolve to /config.json from the static folder
        .then(response => response.json())
        .then(data => setConfig(data))
        .catch(error => console.error('Error fetching config:', error));
    }, []);
    
    const p1 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/association/LitreryClub/ShowNTell/1.webp`;
    const p2 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/association/LitreryClub/ShowNTell/2.webp`;
    const p3 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/association/LitreryClub/ShowNTell/3.webp`;
    const p4 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/association/LitreryClub/ShowNTell/4.webp`;
    const p5 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/association/LitreryClub/ShowNTell/5.webp`;
    const p6 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/association/LitreryClub/ShowNTell/6.webp`;
    const p7 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/association/LitreryClub/ShowNTell/7.webp`;
    const p8 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/association/LitreryClub/ShowNTell/8.webp`;
    const p9 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/association/LitreryClub/ShowNTell/9.webp`;
    const p10 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/association/LitreryClub/ShowNTell/10.webp`;
    const p11 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/association/LitreryClub/ShowNTell/11.webp`;
    const p12 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/association/LitreryClub/ShowNTell/12.webp`;
    const p13 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/association/LitreryClub/ShowNTell/13.webp`;

    const photos = [
        {
            src: p1,
            source: p1,
            rows: 1,
            cols: 2,
        },
        {
            src: p2,
            source: p2,
            rows: 1,
            cols: 2,
        },
        {
            src: p3,
            source: p3,
            rows: 1,
            cols: 1,
        },
        {
            src: p10,
            source: p10,
            rows: 1,
            cols: 1,
        }, 
        {
            src: p4,
            source: p4,
            rows: 1,
            cols: 2,
        },
        {
            src: p5,
            source: p5,
            rows: 1,
            cols: 2,
        }, 
        {
            src: p6,
            source: p6,
            rows: 1,
            cols: 1,
        }, 
        {
            src: p12,
            source: p12,
            rows: 1,
            cols: 1,
        },
        {
            src: p7,
            source: p7,
            rows: 1,
            cols: 2,
        },
        {
            src: p8,
            source: p8,
            rows: 1,
            cols: 1,
        }, 
        {
            src: p9,
            source: p9,
            rows: 1,
            cols: 1,
        }, 
        {
            src: p11,
            source: p11,
            rows: 1,
            cols: 2,
        },
      
        {
            src: p13,
            source: p13,
            rows: 1,
            cols: 2,
        },
    ];

    return (
        <Main>

            <Container>

                <Grid container spacing={4}>
                    <Grid item xs={12} md={8}>
                        <Box>

                            <Box paddingX={{ xs: 0, sm: 4, md: 6 }}>
                                <Typography variant='h5' align='center' >
                                SHOW N TELL
                                </Typography>
                                <Typography
                                    fontSize="x-small"
                                ><br /><br />
                                    Class: Pre-Primary  &  Date: 27 JULY 2023
                                </Typography>

                                <br />
                                <Typography variant={'subtitle1'} align={'justify'}>
                                Event of the Month - Show and Tell Activity organised for the students of Pre-Primary was conducted on Tuesday, 25 July 2023 for the students of Montessori, Wednesday, 26 July 2023 for the students of Kindergarten and Thursday, 27 July 2023 for the students of Pre-K and Pre-Nurture.
                                    <br></br>
                                The event was much to the delight of the students as they were eager to participate and present their speaking skills on the topic “ My Family”. 
                                <br></br>
                                The students used colourful and carefully crafted props to support their presentation and used the platform given to them to share about their family. These innovative presentations made the event even more enjoyable.
                                <br></br>
                                Show and Tell Activity provided an excellent platform for language development. Students practised speaking, listening and descriptive skills, exploring their vocabulary in an enjoyable setting.
                                <br></br>
                                Furthermore, the Show and Tell Activity on the topic "My Family" was a resounding success. It showcased the fondness, love and respect that the students have for their families. They wish to keep their family safe in their prayers and actions. The beaming faces of our students and their confident expressions showcased the positive impact of such activities on their overall development.
                                </Typography>
                            </Box>
                            <Typography
                                variant={'h6'}
                                color={'primary'}
                                align={'center'}
                                fontWeight={'normal'}
                            ><br />
                                "Nothing can dim the light which shines from within…"

                                <br />
                            </Typography>

                            <Box>
                                <ImageList
                                    variant="quilted"
                                    cols={2}
                                    rowHeight={isMd ? 300 : 200}
                                    gap={isMd ? 16 : 4}
                                >
                                    {photos.map((item, i) => (
                                        <ImageListItem key={i} cols={item.cols} rows={item.rows}>
                                            <LazyLoadImage
                                                height={'100%'}
                                                width={'100%'}
                                                src={item.src}
                                                alt="..."
                                                effect="blur"
                                                onClick={() => openLightbox(i)}
                                                style={{
                                                    objectFit: 'cover',
                                                    filter:
                                                        theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                                                    cursor: 'poiner',
                                                    borderRadius: 8,
                                                }}
                                            />
                                        </ImageListItem>
                                    ))}
                                </ImageList>
                            </Box>
                            {viewerIsOpen && (
                                <Lightbox
                                    mainSrc={photos[currentImage].src}
                                    nextSrc={photos[(currentImage + 1) % photos.length].src}
                                    prevSrc={
                                        photos[(currentImage + photos.length - 1) % photos.length].src
                                    }
                                    onCloseRequest={() => closeLightbox()}
                                    onMovePrevRequest={() =>
                                        setCurrentImage((currentImage + photos.length - 1) % photos.length)
                                    }
                                    onMoveNextRequest={() =>
                                        setCurrentImage((currentImage + 1) % photos.length)
                                    }
                                    reactModalStyle={{ overlay: { zIndex: 1500 } }}
                                />
                            )}
                        </Box>
                    </Grid>

                    <Grid item xs={12} md={4}>

                        <Box marginBottom={4}>
                                    <Sidebar2023/>
                        </Box>
                        <Box marginBottom={4}>
                            <LiterarySidebar/>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Main>


    );
};

export default Literaryclub2023;